@if ( customer ) {
<form [formGroup]="customerForm">
      <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                  ¿Cuánto cupo deseas editarle al cliente?
            </h4>
            <button insert type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
      </div>

      <div class="modal-body">
            <!-- total cupo aprobado -->
            <div class="row">
                  <div class="d-flex gap-2">
                        <div class="card mb-2 w-100 border border-success border-opacity-25 rounded">
                              <div class="card-body p-3">
                                    <h4 class="card-title">Cupo Aprobado</h4>
                                    <h2>{{ customer.total_approved_quota | currency : "$" : "symbol" : "1.0-0" }}</h2>
                                    <div>
                                          <p class="card-text">¡Total cupo aprobado del cliente!</p>
                                    </div>
                              </div>
                        </div>

                        @if ( customer.pagare_signed == 'No' ) {
                        <div class="card mb-2 w-100 border border-success border-opacity-25 rounded">
                              <div class="card-body p-3">
                                    <div class="d-flex gap-2 position-relative">
                                          <h4 class="card-title">Cupo no activado</h4>
                                          <h2 class="position-absolute">
                                                <i class="fa-solid fa-circle-exclamation mr-2 text-danger" style="font-size: 19px"></i>
                                          </h2>
                                    </div>
                                    <div>
                                          <p class="card-text">El cliente aún no ha firmado el pagaré.</p>
                                          <small>https://creditos.somosziro.com/firma/pagare/{{customer.identification}}</small>
                                    </div>
                              </div>
                        </div>
                        }
                  </div>
            </div>

            <div class="row">
                  <div class="col-md-6 mb-3">
                        <div class="form-group">
                              <label for="identification">Identificación</label>
                              <input type="text" class="form-control" formControlName="identification"
                                    id="identification">
                        </div>
                  </div>
                  <div class="col-md-6 mb-3">
                        <div class="form-group">
                              <label for="nit">Nit</label>
                              <input type="text" class="form-control" formControlName="nit" id="nit">
                        </div>
                  </div>
                  <div class="col-md-6 mb-3">
                        <div class="form-group">
                              <label for="name">Nombres</label>
                              <input type="text" class="form-control" formControlName="name" id="name">
                        </div>
                  </div>
                  <div class="col-md-6 mb-3">
                        <div class="form-group">
                              <label for="last_name">Apellidos</label>
                              <input type="text" class="form-control" formControlName="last_name" id="last_name">
                        </div>
                  </div>
                  <div class="col-md-6 mb-3">
                        <div class="form-group">
                              <label for="buss_name">Nombre negocio</label>
                              <input type="text" class="form-control" formControlName="buss_name" id="buss_name">
                        </div>
                  </div>
                  <div class="col-md-6 mb-3">
                        <div class="form-group">
                              <label for="email">Email</label>
                              <input type="text" class="form-control" formControlName="email" id="email">
                        </div>
                  </div>
                  <div class="col-md-6 mb-3">
                        <div class="form-group">
                              <label for="phone">Télefono</label>
                              <input type="text" class="form-control" formControlName="phone" id="phone">
                        </div>
                  </div>
                  <div class="col-md-6 mb-3">
                        <div class="form-group">
                              <label for="city_id">Ciudad</label>
                              <select class="form-control" formControlName="city_id" id="city_id">
                                    @for( city of cities; track $index) {
                                          <option [value]="city.id">{{ city.name }}</option>
                                    }
                              </select>
                        </div>
                  </div>
                  <div class="col-md-6 mb-3">
                        <div class="form-group">
                              <label for="address">Dirección personal</label>
                              <input type="text" class="form-control" formControlName="address" id="address">
                        </div>
                  </div>
                  <div class="col-md-6 mb-3">
                        <div class="form-group">
                              <label for="businness_address">Dirección empresa</label>
                              <input type="text" class="form-control" formControlName="businness_address"
                                    id="businness_address">
                        </div>
                  </div>
                  <div class="col-md-6 mb-3">
                        <div class="form-group">
                              <label for="total_approved_quota">Cupo aprobado</label>
                              <input type="text" class="form-control" formControlName="total_approved_quota"
                                    id="total_approved_quota">
                        </div>
                  </div>

            </div>
            <div class="d-flex justify-content-end">
                  @if ( canSendWhatsapp ) {
                        <button type="button" class="btn btn-success me-2" (click)="sendWhatsappLinkDeceval()">
                              Enviar enlace de pagare
                        </button>
                  }
                  <button insert type="button" class="btn btn-danger me-2" (click)="closeModal()">
                        Cerrar
                  </button>
                  <button type="button" class="btn btn-secondary d-flex align-items-center gap-2" (click)="customerUpdate()">
                        Actualizar  <i class="fa-solid fa-wand-magic-sparkles"></i>
                  </button>
            </div>
      </div>
</form>
} @else {
      <h1> Buscando... </h1>
}
